* {
	font-family: "DM Sans", sans-serif;
	box-sizing: border-box;
	text-decoration: none;
}

body {
	background-color: #f4f4f4;
	margin: 0;
	height: 100vh;
}

#main {
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	min-height: 100%;
}
