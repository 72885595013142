* {
  box-sizing: border-box;
  font-family: DM Sans, sans-serif;
  text-decoration: none;
}

body {
  background-color: #f4f4f4;
  height: 100vh;
  margin: 0;
}

#main {
  flex-direction: column;
  flex: auto;
  min-height: 100%;
  display: flex;
}
/*# sourceMappingURL=index.f23a2390.css.map */
